import { useCallback, useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import AuthorizedLayout from 'layouts/authorized';

import { TheaterMasksIcon } from 'assets/icons';

import { proxyApi } from 'resources/proxy';

import CircularProgress from '@mui/material/CircularProgress';

import Button from 'components/Button';
import Pagination from 'components/Pagination';

import useWindowDimensions from 'hooks/useWindowDimensions';

import ProxyItem from './components/ProxyItem';

import './styles.scss';

const ROW_HEIGHT = 72;

const DomainMasking = () => {
  const { height } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(+searchParams.get('page') || 1);

  // 400 - other elements height on page
  const perPageValue = Math.floor((height - 350) / ROW_HEIGHT);
  const perPage = perPageValue > 0 ? perPageValue : 1;

  const {
    data,
    isLoading,
    isFetching,
  } = proxyApi.useList({
    page,
    perPage,
  });
  const [fetchedMonitors, totalItems] = data ? [data.items, data.totalItems] : [null, null];

  const isEmptyState = !isLoading && !data?.items.length;

  useEffect(() => {
    if (fetchedMonitors) {
      setItems(fetchedMonitors);
    }
  }, [fetchedMonitors]);

  useEffect(() => {
    if (!totalItems) {
      return;
    }

    const totalPages = Math.ceil(totalItems / perPage);

    if (page > totalPages) {
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
  }, [totalItems, perPage, page, searchParams, setSearchParams]);

  useEffect(() => {
    const queryPage = +searchParams.get('page');

    if (queryPage !== page) {
      setPage(queryPage || 1);
    }
  }, [page, searchParams]);

  const onAddNewClick = useCallback(() => {
    navigate(routes.domainMaskingAdd.path);
  }, [navigate]);

  const onPageChanged = useCallback((newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const EmptyState = () => (
    <div className="empty-state">
      <TheaterMasksIcon />

      <p className="empty-state-title">Add Domain Masking Proxies</p>

      <Button
        onClick={onAddNewClick}
      >
        Add a new proxy
      </Button>
    </div>
  );

  return (
    <AuthorizedLayout>
      <div className="domain-masking-wrapper">
        <div>
          <div className="title">
            <p>Domain Masking Proxies</p>

            {!isLoading && !isEmptyState && (
            <Button
              onClick={onAddNewClick}
            >
              Add Monitor
            </Button>
            )}
          </div>
        </div>

        {isEmptyState && <EmptyState />}

        {!!items.length && (
        <div className="list">
          {isFetching && <CircularProgress className="table-loader" />}

          <div className="table-head">
            <p className="masking-domain-head">Masking Domain</p>
            <p className="primary-domain-head">Primary Domain</p>
            <p className="cluster-ip-head">Cluster IP Adress</p>
            <p className="seo-safe-head">SEO-Safe</p>
            <p className="reputation-head">Reputation</p>
            <p className="status-head">Status</p>
          </div>

          <div className="table-body">
            {items.map((item) => <ProxyItem key={item.id} item={item} />)}
          </div>

          {totalItems > items.length && (
          <Pagination
            className="table-pagination"
            activePage={page}
            perPage={perPage}
            totalItems={totalItems}
            onPageChanged={onPageChanged}
          />
          )}
        </div>
        )}

        {isLoading && <CircularProgress className="loader" />}
      </div>
    </AuthorizedLayout>
  );
};

export default DomainMasking;
